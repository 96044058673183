import { selectedChainAtom, targetChainAtom, urlBaseAtom } from '@apps-orangefi/lib/store'
import { AppFooter } from '@apps-orangefi/ui/molecules/v1'
import { ErrorFallback } from '@apps-orangefi/ui/organisms'
import { AppPageHeader } from '@apps-orangefi/ui/organisms/v1'
import { useUpdateBlockNumber } from '@apps-orangefi/wagmi/hooks'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { useRouter } from 'next/router'
import { ReactElement, useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { SkeletonTheme } from 'react-loading-skeleton'
import { useAccount } from 'wagmi'

type LayoutProps = Required<{
  readonly children: ReactElement
}>

export const Layout = ({ children }: LayoutProps) => {
  const { address, chainId, chain } = useAccount()
  const { supportedChainIds, defaultChainId } = useAtomValue(targetChainAtom)
  const urlBase = useAtomValue(urlBaseAtom)

  // TODO: remove when selecor is ready
  const [selectedChain, setSlectedChain] = useAtom(selectedChainAtom)

  const currentChainId = chainId ?? defaultChainId
  const isSupported = supportedChainIds.includes(currentChainId)

  useUpdateBlockNumber(currentChainId, isSupported)

  useEffect(() => {
    if (window?.MetaCRMTracking?.manualConnectWallet) {
      window.MetaCRMTracking.manualConnectWallet(address)
    }
  }, [address])

  useEffect(() => {
    if (chain?.id === selectedChain?.id) {
      setSlectedChain(chain)
    }
  }, [chain])

  const router = useRouter()

  return (
    <SkeletonTheme baseColor="#1e212a" highlightColor="#383b45">
      <div className="flex flex-col justify-between h-full">
        <AppPageHeader />
        <main className="mb-auto mt-20 md:mt-24 flex-1">
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <>{children}</>
          </ErrorBoundary>
        </main>
        <AppFooter currentPath={router.asPath} pointsUrlBase={urlBase.points} />
      </div>
    </SkeletonTheme>
  )
}

export const getLayout = (page: ReactElement) => {
  return <Layout>{page}</Layout>
}
