import ArrowDownIcon from '@apps-orangefi/assets/images/arrow-narrow-down.svg'
import ArrowUpIcon from '@apps-orangefi/assets/images/arrow-narrow-up.svg'
import ChevronRightIcon from '@apps-orangefi/assets/images/chevron-right.svg'
import { usePathByChain, useProductTypeSelector } from '@apps-orangefi/hooks'
import { BN } from '@apps-orangefi/lib'
import {
  Amm,
  Derivative,
  ProductCardProps,
  ProductType,
  productType,
  MerklRewardStatus,
  merklRewardStatus,
  RewardAPR,
} from '@apps-orangefi/lib/types'
import { numberWithShortScale } from '@apps-orangefi/lib/utils'
import { Platform } from '@apps-orangefi/ui/atoms'
import { ProductTypeSelector, TokenPairBySymbol } from '@apps-orangefi/ui/molecules'
import { TotalAPR } from '@apps-orangefi/ui/molecules/list-item'
import { ProductTableRowSkeleton } from '@apps-orangefi/ui/molecules/skeletons'
import {
  SortDirection,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { isEqual } from 'lodash'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Address } from 'viem'

type Props = {
  prodcutCardList: ProductCardProps[]
  fetching: boolean
}

type Product = {
  vaultAddress: AddressType
  pairName: string
  baseTokenSymbol: string
  quoteTokenSymbol: string
  totalAPR: BN | null
  feeAPR: BN | null
  strykeReward:
    | {
        totalApr: BN
        rewardList: RewardAPR[]
      }
    | undefined
  tvl: BN
  position: BN | undefined
  reservedLpAmountUSD: BN | undefined
  amm: Amm | undefined
  derivative: Derivative | undefined
  productType: ProductType[]
  merklRewardStatus: MerklRewardStatus
  showStrykeReward: boolean
}

const columnHelper = createColumnHelper<Product>()

const columns = [
  columnHelper.accessor('pairName', {
    header: 'PAIR',
    cell: props => {
      return (
        <div className="flex flex-row items-center">
          <TokenPairBySymbol
            baseTokenSymbol={props.row.original.baseTokenSymbol}
            quoteTokenSymbol={props.row.original.quoteTokenSymbol}
            size={24}
          />
          <div className="ml-7 type-sm-semibold">{props.getValue()}</div>
          {props.row.original.productType.includes(productType.OrangePoints) && (
            <span className="flex items-center type-xs-caption dark:text-orange-500 dark:bg-orange-200 rounded-full px-1.5 py-1 ml-2.5">
              Points
            </span>
          )}
        </div>
      )
    },
    sortingFn: 'alphanumeric',
    size: 350,
  }),
  columnHelper.accessor('totalAPR', {
    header: 'TOTAL APR',
    cell: props => {
      if (props) {
        return (
          <TotalAPR
            totalAPR={props.getValue()}
            feeAPR={props.row.original.feeAPR}
            strykeReward={props.row.original.strykeReward}
            rewardStatus={props.row.original.merklRewardStatus}
            showStrykeReward={props.row.original.showStrykeReward}
          />
        )
      } else {
        return <div>0%</div>
      }
    },
    sortingFn: (rowA, rowB, columnId) => {
      return (rowA.original.totalAPR ?? new BN(0)).gt(rowB.original.totalAPR ?? new BN(0)) ? 1 : -1
    },
    size: 250,
  }),
  columnHelper.accessor('tvl', {
    header: 'TVL',
    cell: cell => {
      return (
        <div className="flex flex-row items-baseline justify-between sm:justify-start border-l border-gray-700 sm:border-none ml-4 sm:ml-0 pl-3 sm:pl-0">
          <span className="type-sm-caption dark:text-gray-300 sm:hidden">TVL</span>
          <div className="type-sm-caption font-mono dark:text-white">
            ${numberWithShortScale(cell.getValue(), 2)}
          </div>
        </div>
      )
    },
    sortingFn: (rowA, rowB, columnId) => {
      return (rowA.original.tvl ?? new BN(0)).gt(rowB.original.tvl ?? new BN(0)) ? 1 : -1
    },
    size: 100,
  }),
  columnHelper.accessor('position', {
    // header: 'POSITION (RESERVE)',
    header: 'POSITION',
    cell: props => {
      // const reservedLpAmountUSD = props.row.original.reservedLpAmountUSD
      // const isShowReserved = !!reservedLpAmountUSD && !reservedLpAmountUSD.isZero()

      return (
        <div className="flex flex-row items-baseline justify-between sm:justify-end">
          <span className="type-sm-caption dark:text-gray-300 sm:hidden">POSITION</span>
          <div
            className={`flex flex-col items-end sm:relative sm:right-10 ${
              // isShowReserved ? 'sm:top-2' : ''
              ''
            }`}
          >
            <div className="type-sm-caption font-mono dark:text-white">
              {!props.getValue() || props.getValue()!.isZero()
                ? '-'
                : `$${numberWithShortScale(props.getValue(), 2)}`}
            </div>
            {/* <div className="type-sm-medium mt-0.5">
              {isShowReserved && (
                <>
                  (
                  <span className="font-mono dark:text-white">
                    ${numberWithShortScale(reservedLpAmountUSD, 2)}
                  </span>
                  )
                </>
              )}
            </div> */}
          </div>
        </div>
      )
    },
    sortingFn: (rowA, rowB, columnId) => {
      return (rowA.original.position ?? new BN(0)).gt(rowB.original.position ?? new BN(0)) ? 1 : -1
    },
    size: 200,
  }),
  columnHelper.accessor('amm', {
    header: 'DERIVATIVES / DEX',
    cell: props => {
      return (
        <div className="flex flex-row justify-between relative">
          <Platform
            amm={props.row.original.amm}
            derivative={props.row.original.derivative}
            noText
          />
          <Image
            src={ChevronRightIcon}
            width={16}
            height={16}
            alt="right-arrow"
            className="focused-item"
          />
        </div>
      )
    },
    enableSorting: false,
  }),
  columnHelper.accessor('productType', {
    enableSorting: false,
    filterFn: (row, columnId, filterValue) => {
      return row.original.productType.includes(filterValue)
    },
  }),
]

const productTypeItems = [
  {
    label: 'All',
    value: null,
  },
  {
    label: 'Dual Liquidity',
    value: productType.DualLiquidity,
  },
  {
    label: 'Bluechip',
    value: productType.BlueChip,
  },
]

export const ProductTable = ({ prodcutCardList, fetching }: Props) => {
  const [data, setData] = useState<Product[]>([])
  const router = useRouter()
  const path = router.asPath

  useEffect(() => {
    const isBerachain = path.includes('berachain')

    const productList = prodcutCardList.map(product => {
      return {
        vaultAddress: product.vaultAddress,
        pairName: product.title,
        baseTokenSymbol: product.baseToken.symbol,
        quoteTokenSymbol: product.quoteToken.symbol,
        totalAPR: product.apr,
        feeAPR: product.feeApr ?? null,
        showStrykeReward: !!product.strykeGaugeId || isBerachain,
        strykeReward: product.strykeReward,
        tvl: product.tvl,
        position: product.myPositionUSD,
        reservedLpAmountUSD: product.reservedLpAmountUSD,
        amm: product.platform?.amm,
        derivative: product.platform?.derivative,
        productType: product.productType ?? [],
        merklRewardStatus: product.merklRewardStatus,
      }
    })
    if (!isEqual(productList, data)) {
      setData(productList)
    }
  }, [prodcutCardList, path])

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    initialState: {
      sorting: [
        {
          id: 'tvl',
          desc: true, // sort by tvl in descending order by default
        },
      ],
    },
  })
  const sortArrows = useMemo(() => {
    return {
      asc: <Image src={ArrowUpIcon} width={16} height={16} alt="arrow-up" />,
      desc: <Image src={ArrowDownIcon} width={16} height={16} alt="arrow-down" />,
    }
  }, [])

  const { selectedProductType, onSelectProductType } = useProductTypeSelector()

  useEffect(() => {
    switch (selectedProductType) {
      case productType.OrangePoints:
        table.setColumnFilters([{ id: 'productType', value: productType.OrangePoints }])
        break
      case productType.DualLiquidity:
        table.setColumnFilters([{ id: 'productType', value: productType.DualLiquidity }])
        break
      case productType.BlueChip:
        table.setColumnFilters([{ id: 'productType', value: productType.BlueChip }])
        break
      case productType.Pegged:
        table.setColumnFilters([{ id: 'productType', value: productType.Pegged }])
        break
      default:
        table.resetColumnFilters()
        break
    }
  }, [selectedProductType, table])

  const onClickLink = useCallback(
    (vaultAddress: string) => {
      router.push(`${path}/${vaultAddress}`)
    },
    [path]
  )

  return (
    <div className="flex flex-col sm:flex-row mt-6">
      <ProductTypeSelector
        items={productTypeItems}
        onSelect={onSelectProductType}
        className="sm:mr-4"
      />
      <div className="place-content-start w-full gap-4 md:gap-x-8 mt-3 sm:mt-0">
        <table className="w-full border-separate border-spacing-y-0.5 table-auto">
          <thead className="hidden sm:table-header-group">
            {table.getHeaderGroups().map(headerGroup => (
              <tr
                key={headerGroup.id}
                className="dark:bg-gray-800 dark:text-gray-250 bg-cards text-darkTheme-500 type-sm-caption"
              >
                {headerGroup.headers.map(header =>
                  header.id === 'productType' ? null : (
                    <th
                      key={header.id}
                      onClick={header.column.getToggleSortingHandler()}
                      className={`py-3 text-start first:rounded-tl-2xl first:pl-5 last:rounded-tr-2xl last:pr-5 first:rounded-bl last:rounded-br
                      ${header.column.getCanSort() ? 'cursor-pointer select-none' : ''}`}
                      style={{ width: `${header.getSize()}px` }}
                    >
                      <div className="flex flex-row items-center">
                        <span>
                          {header.isPlaceholder
                            ? null
                            : flexRender(header.column.columnDef.header, header.getContext())}
                        </span>
                        <span className="ml-2">
                          {sortArrows[header.column.getIsSorted() as SortDirection]}
                        </span>
                      </div>
                    </th>
                  )
                )}
              </tr>
            ))}
          </thead>
          <tbody className="flex flex-col gap-2 sm:table-row-group">
            {fetching ? (
              <>
                <ProductTableRowSkeleton />
                <ProductTableRowSkeleton />
                <ProductTableRowSkeleton />
              </>
            ) : (
              <>
                {table.getRowModel().rows.map(row => (
                  <tr
                    key={row.id}
                    className="dark:bg-gray-850 bg-cards hover:cursor-pointer flex flex-row flex-wrap items-center sm:table-row sm:product-list-item p-3 sm:p-0 rounded-lg sm:rounded-none"
                    onClick={e => {
                      if (e.ctrlKey || e.metaKey) {
                        window.open(`${path}/${row.original.vaultAddress}`, '_blank')
                      } else {
                        onClickLink(row.original.vaultAddress)
                      }
                    }}
                  >
                    {row.getVisibleCells().map(cell =>
                      cell.column.id === 'productType' ? null : (
                        <td
                          key={cell.id}
                          className={`block sm:table-cell sm:first:pl-5 sm:last:pr-5 py-1 sm:py-3 sm:first:rounded-l sm:last:rounded-r`}
                          // ${
                          //   columnWidth[cell.column.id] ?? ''
                          // }
                          //  sm:w-auto
                          style={{ width: `${cell.column.getSize()}px` }}
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      )
                    )}
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}
