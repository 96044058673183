import { useModal } from '@apps-orangefi/hooks'
import { StrykeLP } from '@apps-orangefi/lib/types'
import { ReserveCard } from '@apps-orangefi/ui/molecules/cards'
import { useIsApporvedStrykeLP, useReserveHelpers } from '@apps-orangefi/wagmi/hooks'
import { useCallback, useMemo } from 'react'

import { MODAL_TYPES } from '../modals'

type Props = StrykeLP & {
  account: AddressType | undefined
  refetch: () => void
  className?: string
}

export const ReserveCardContainer = ({
  account,
  title,
  baseTokenSymbol,
  quoteTokenSymbol,
  amm,
  derivative,
  chain,
  positionManagerAddress,
  handlerAddress,
  lps,
  tokenTotalLiquidity,
  totalAmountUSD,
  refetch,
  className,
}: Props) => {
  const { showModal, hideModal } = useModal()

  const onSubmit = useCallback(() => {
    showModal({
      modalType: MODAL_TYPES.ReserveAllLPModalContainer,
      modalProps: {
        title: 'Reserve Liquidity transaction',
        positionManagerAddress,
        handlerAddress,
        lps,
        handleClose: () => {
          hideModal()
          setTimeout(() => refetch(), 5000)
        },
      },
    })
  }, [positionManagerAddress, handlerAddress, lps])

  const buttonText = useMemo(() => {
    if (lps.length === 0 || totalAmountUSD.isZero()) {
      return 'No LP'
    }
    return 'Reserve'
  }, [lps, totalAmountUSD])

  return (
    <ReserveCard
      title={title}
      baseTokenSymbol={baseTokenSymbol}
      quoteTokenSymbol={quoteTokenSymbol}
      amm={amm}
      derivative={derivative}
      totalAmountUSD={totalAmountUSD}
      tokenTotalLiquidity={tokenTotalLiquidity}
      buttonLabel={buttonText}
      onSubmit={onSubmit}
      className={className}
    />
  )
}
