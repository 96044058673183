import { useConnectWalletModal, useAccountModal, modalVersion } from '@apps-orangefi/hooks'
import { useSwitchChainModal } from '@apps-orangefi/hooks'
import { selectedChainAtom, targetChainAtom, urlBaseAtom } from '@apps-orangefi/lib/store'
import { genLinkChainAction } from '@apps-orangefi/lib/utils'
import { AppHeader } from '@apps-orangefi/ui/molecules/v1'
import { useAtom, useAtomValue } from 'jotai'
import { findKey } from 'lodash'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useMemo } from 'react'
import { useAccount } from 'wagmi'
import { Chain, arbitrum, berachain } from 'wagmi/chains'

export const AppPageHeader = () => {
  const { supportedChainIds, defaultChainId, supportedChains } = useAtomValue(targetChainAtom)
  const urlBase = useAtomValue(urlBaseAtom)
  const [selectedChain, setSelectedChain] = useAtom(selectedChainAtom)
  const { address: account, isConnected, chain } = useAccount()
  const router = useRouter()

  const { openConnectWalletModal } = useConnectWalletModal(modalVersion.StrategyVault)
  const { openAccountModal } = useAccountModal(account, chain)

  // TODO: commonize this
  const onSuccessSwitchChain = useCallback(
    (chainId: number) => {
      const chainNetwork =
        findKey(supportedChains, (chain: Chain) => chain.id === chainId) ?? 'arbitrum'
      router.push(`/${chainNetwork}`)
    },
    [router]
  )
  const { isActiveChainSupported, isLoading, openSwitchChainModal, onSelectChain } =
    useSwitchChainModal(supportedChainIds, defaultChainId, onSuccessSwitchChain)

  const networkStatus = {
    isConnected,
    isActiveChainSupported,
    isActiveChainIsSelected: selectedChain?.id === chain?.id,
  }

  useEffect(() => {
    Object.values(supportedChains).find(supportedChain => {
      if (supportedChain.id === chain?.id) {
        setSelectedChain(supportedChain)
      }
    })
  }, [chain])

  const onLinkChain = useMemo(
    () => genLinkChainAction(router, selectedChain),
    [router, selectedChain]
  )

  const chainSelectorProps = useMemo(() => {
    return {
      selectedChain,
      supportedChains,
      // onSelectChain,
      onSelectChain: onLinkChain,
    }
  }, [selectedChain, onLinkChain])

  return (
    <>
      <AppHeader
        networkStatus={networkStatus}
        account={account}
        openConnectWalletModal={openConnectWalletModal}
        openAccountModal={openAccountModal}
        openSwitchChainModal={openSwitchChainModal}
        chainSelectorProps={chainSelectorProps}
        currentPath={router.asPath}
        pointsUrlBase={urlBase.points}
      />
    </>
  )
}
